import { computed, reactive, Ref, ref } from 'vue';
import { useQuery, UseQueryOptions } from '@tanstack/vue-query';

import { Horizon } from '@stellar/stellar-sdk';
import QUERY_KEYS from '@/constants/queryKeys';
import { BalanceMap } from '@/services/token/concerns/balances.concern';

import useWeb3 from '@/services/web3/useWeb3';
import { default as ERC20_ABI } from '@/lib/abi/ERC20.json';

import useNetwork from '../useNetwork';
import { getMulticaller } from '@/dependencies/Multicaller';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { useAccount } from '@/stellar-hooks/useAccounts';
import { tokensByNetworkId } from '@/lib/config/tokens';
import { OtherNetwork } from '@/lib/config';
import { getAddress, isAddress } from '@ethersproject/address';
import { getNetwork } from '@stellar/freighter-api';

/**
 * TYPES
 */
type QueryResponse = BalanceMap;
type QueryOptions = UseQueryOptions<QueryResponse>;
interface QueryInputs {
  tokenAddresses: Ref<string[]>;
  isEnabled?: Ref<boolean>;
}

/**
 * Fetches all balances for provided tokens.
 */
export default function useBalancesQuery({
  tokenAddresses,
  isEnabled = ref(true),
}: QueryInputs) {
  /**
   * COMPOSABLES
   */
  const {
    account,
    isWalletReady,
    getProvider: getWeb3Provider,
    smartAccount,
  } = useWeb3();
  const { networkId, isStellar, networkConfig } = useNetwork();
  const { address: stellarAddress } = useAccount();

  /**
   * COMPUTED
   */
  const enabled = computed(
    () =>
      isWalletReady.value &&
      !!networkConfig &&
      isEnabled.value &&
      (isStellar.value ? !!stellarAddress.value : true)
  );
  const web3Provider = computed(() => getWeb3Provider());

  /**
   * QUERY INPUTS
   */
  const queryKey = reactive(
    QUERY_KEYS.Account.Balances(
      networkId,
      account || stellarAddress,
      tokenAddresses
    )
  );

  const queryFn = async () => {
    // const tokenAddresses = ref(['0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE']);
    if (!networkConfig) {
      throw new Error('networkConfig is undefined');
    }

    const hasNativeToken = tokenAddresses.value.some(
      token => token === networkConfig.nativeAsset.address
    );
    const erc20TokenAddresses = tokenAddresses.value.filter(
      addr => addr !== networkConfig.nativeAsset.address
    );

    let balanceResult = {} as Record<string, { balanceOf: BigNumber }>;
    let decimalsResult = {} as Record<string, { decimals: number }>;

    if (!isStellar.value && erc20TokenAddresses.length) {
      const Multicaller = getMulticaller();
      const multicaller = new Multicaller();

      erc20TokenAddresses.forEach(tokenAddress => {
        multicaller.call({
          key: `${account.value}.${tokenAddress}.balanceOf`,
          address: tokenAddress,
          function: 'balanceOf',
          abi: ERC20_ABI,
          params: [account.value],
        });
      });

      balanceResult = (await multicaller.execute())[account.value];
    }

    console.log('useBalancesQuery.ts - networkId.value');
    console.log(networkId.value);
    if (!isStellar.value && erc20TokenAddresses.length) {
      const Multicaller = getMulticaller();
      const multicaller = new Multicaller();

      erc20TokenAddresses.forEach(tokenAddress => {
        multicaller.call({
          key: `${account.value}.${tokenAddress}.decimals`,
          address: tokenAddress,
          function: 'decimals',
          abi: ERC20_ABI,
        });
      });

      decimalsResult = (await multicaller.execute())[account.value];
    }

    const tokenBalanceByAddress = {} as Record<string, string>;

    if (isStellar.value == true) {
      if (stellarAddress.value) {
        const _stellarNetworkConnected = await getNetwork();
        const isStellarMainnet = _stellarNetworkConnected.network == 'PUBLIC';
        const horizonUrl = isStellarMainnet
          ? 'https://horizon.stellar.org'
          : 'https://horizon-testnet.stellar.org';
        const usdcIssuer = isStellarMainnet
          ? 'GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN'
          : 'GDS6B2CZTPNJRDEARO4Q64EJAJ5E2KLBVPC4PIDACCEHISK5QTAKCMM3';
        const USDYC_ISSUER = isStellarMainnet
          ? 'GDDRGUTO7QX55E5KN3NQWIYRJZ2DI2KPJ4WCMOJ6UJX4C5YNGFLEVAWE'
          : 'GDS6B2CZTPNJRDEARO4Q64EJAJ5E2KLBVPC4PIDACCEHISK5QTAKCMM3';
        const server = new Horizon.Server(
          horizonUrl // horizon url
        );
        const accountBalances = await server
          .accounts()
          .accountId(stellarAddress.value)
          .call();
        const balances: [
          {
            asset_issuer: string;
            asset_code: string;
            balance: string;
            asset_type: string;
          }
        ] = accountBalances.balances;
        erc20TokenAddresses.forEach(token => {
          const tokenDetail = tokensByNetworkId[OtherNetwork.STELLAR].find(e =>
            isAddress(e.address) ? getAddress(e.address) : e.address == token
          );
          const usdcBalance = balances.find(e => {
            return (
              e.asset_issuer == usdcIssuer && e.asset_code == tokenDetail.symbol
            );
          });
          if (usdcBalance) {
            tokenBalanceByAddress[token] = usdcBalance?.balance;
          }
        });
        const usdycBalance = balances.find(e => {
          return e.asset_issuer == USDYC_ISSUER && e.asset_code == 'USDyc';
        });
        if (usdycBalance) {
          tokenBalanceByAddress['0x036CbD53842c5426634e7929541eC2318f3dCF7e'] =
            usdycBalance?.balance;
        }
        if (hasNativeToken) {
          const nativeTokenBalance = balances.find(
            e => e.asset_type == 'native'
          );
          if (nativeTokenBalance) {
            tokenBalanceByAddress[networkConfig.nativeAsset.address] =
              nativeTokenBalance?.balance;
          }
        }
      }
    } else {
      Object.entries(balanceResult).forEach(([tokenAddress, tokenBalance]) => {
        const decimals = decimalsResult[tokenAddress]?.decimals;
        if (!decimals) {
          return;
        }

        const floatBalance = formatUnits(tokenBalance.balanceOf, decimals);
        tokenBalanceByAddress[tokenAddress] = floatBalance;
      });

      if (hasNativeToken) {
        let result;
        if (smartAccount.value) {
          const balances = await smartAccount.value.getBalances();
          result = balances[0].amount;
        } else {
          result = await web3Provider.value.getBalance(account.value);
        }

        const floatBalance = formatUnits(
          result,
          networkConfig.nativeAsset.decimals
        );
        tokenBalanceByAddress[networkConfig.nativeAsset.address] = floatBalance;
      }
    }

    return tokenBalanceByAddress;
  };

  const queryOptions = reactive({
    enabled,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  return useQuery<QueryResponse>(
    queryKey,
    queryFn,
    queryOptions as QueryOptions
  );
}
