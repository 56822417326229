import config, {
  allConfig,
  AllNetwork,
  Network,
  OtherNetwork,
} from '@/lib/config';
import { configService } from '@/services/config/config.service';
import { RouteParamsRaw } from 'vue-router';
import { Config } from '@/lib/config/types';

/**
 * STATE
 */
const windowAvailable = typeof window !== 'undefined';
const localStorageNetworkId: Network | null =
  windowAvailable && localStorage.getItem('networkId')
    ? (Number(localStorage.getItem('networkId')) as Network)
    : null;
const routeSlug =
  (windowAvailable && window.location.hash.split(/[/?]/)[1]) ?? '';
const urlNetworkId: Network | null = routeSlug
  ? networkFromSlug(routeSlug)
  : null;

const NETWORK_ID =
  urlNetworkId ||
  localStorageNetworkId ||
  (Number(import.meta.env.VITE_NETWORK) as Network) ||
  Network.CELO;

// FIXME: Currently for production, hard-coded with Celo
if (import.meta.env.MODE === 'production') {
  // NETWORK_ID = Network.CELO;
}

if (windowAvailable) localStorage.setItem('networkId', NETWORK_ID.toString());
export const networkSlug = allConfig[NETWORK_ID].slug;
export const networkConfig = allConfig[NETWORK_ID];

/**
 * COMPUTED
 */

export const networkId = ref<AllNetwork>(NETWORK_ID);

export const isMainnet = computed(() => networkId.value === Network.MAINNET);
export const isPolygon = computed(() => networkId.value === Network.POLYGON);
export const isZkevm = computed(() => false);
export const isOptimism = computed(() => false);
export const isArbitrum = computed(() => networkId.value === Network.ALFAJORES);
export const isStellar = computed(function () {
  return networkId.value === OtherNetwork.STELLAR;
});
export const isGnosis = computed(() => false);
export const isGoerli = computed(() => false);

export const hasBridge = computed<boolean>(() => !!networkConfig.bridgeUrl);
export const isTestnet = computed(() => isGoerli.value);

export const isEIP1559SupportedNetwork = computed(
  () => configService.network.supportsEIP1559
);

export const isPoolBoostsEnabled = computed<boolean>(
  () => configService.network.pools.BoostsEnabled
);

/**
 * METHODS
 */

export function networkFor(key: string | number): Network {
  const network = Object.values(allConfig).find((config: Config) => {
    return config.key === key.toString();
  });
  if (!network) {
    throw new Error('Network not supported');
  }

  return network.chainId as Network;
}

export function getNetworkSlug(network: Network): string {
  return allConfig[network].slug;
}

export function networkFromSlug(networkSlug: string): Network | null {
  const networkConf = Object.values(allConfig).find((config: Config) => {
    return config.slug === networkSlug;
  });
  return networkConf ? (networkConf.chainId as Network) : null;
}

export function appUrl(): string {
  return `https://${configService.env.APP_DOMAIN}/#`;
}

/**
 * Get subdomain, excluding 'beta'
 *
 * @param {string} url - Host url - e.g. "polygon.balancer.fi/".
 * @returns {string} Subdomain.
 */
export function getSubdomain(url: string) {
  const subdomain = url.split('.')[0];
  if (subdomain === 'beta') {
    return url.split('.')[1];
  }
  return subdomain;
}

/**
 * Using networkSlug in url check if redirect is necessary. Redirect is
 * necessary if something about the current state doesn't match the networkSlug.
 *
 * @param {string} networkSlug - Network name in url - e.g. "app.balancer.fi/polygon".
 * @param {Function} noNetworkChangeCallback - Function which gets triggered if user is on correct network already.
 * @param {Function} networkChangeCallback - Function which gets triggered if network change is required.
 */
export function handleNetworkSlug(
  networkSlug: string,
  noNetworkChangeCallback: () => void,
  networkChangeCallback: () => void
) {
  const networkFromUrl = networkFromSlug(networkSlug);
  const localStorageNetwork = networkFor(
    localStorage.getItem('networkId') ?? '1'
  );
  if (!networkFromUrl) {
    // missing or incorrect network name -> next() withtout network change
    return noNetworkChangeCallback();
  }
  if (localStorageNetwork === networkFromUrl) {
    // if on the correct network -> next()
    return noNetworkChangeCallback();
  }

  // if on different network -> update localstorage and reload
  return networkChangeCallback();
}

/**
 * Takes current URL and checks if a redirect URL is required, if not returns undefined.
 *
 * @param {string} host - The current host, e.g. polygon.balancer.fi
 * @param {string} fullPath - The current full path from vue router.
 * @param {RouteParamsRaw} params - The params in the current route.
 * @returns {string|undefined} The URL to redirect to, e.g. https://app.balancer.fi/#/polygon
 */
export function getRedirectUrlFor(
  host: string,
  fullPath: string,
  params: RouteParamsRaw = {}
): string | undefined {
  const subdomain = getSubdomain(host);
  const subdomainNetwork = networkFromSlug(subdomain);

  if (subdomainNetwork) {
    // Legacy network subdomain, we need to redirect to app.balancer.fi.
    const newDomain = appUrl().replace(subdomain, 'app');
    // If networkSlug provided it will be in the fullPath, so pass empty string instead.
    const newNetwork = params?.networkSlug
      ? ''
      : `/${getNetworkSlug(subdomainNetwork)}`;
    return `${newDomain}${newNetwork}${fullPath}`;
  }

  return;
}

export default function useNetwork() {
  const router = useRouter();
  const appNetworkConfig = configService.network;

  function getNetworkChangeUrl(networkSlug?: string): string {
    const routes = [
      'pool',
      'create-pool',
      'add-liquidity',
      'withdraw',
      'migrate-pool',
    ];
    if (routes.includes(router.currentRoute.value.name?.toString() ?? '')) {
      return `/#/${networkSlug}?poolNetworkAlert=true`;
    }

    const currentRoute = router.currentRoute.value;
    return router.resolve({
      name: currentRoute.name ?? 'home',
      params: { ...currentRoute.params, networkSlug },
      query: currentRoute.query,
    }).href;
  }

  return {
    appUrl,
    networkId,
    isStellar,
    networkConfig,
    networkSlug,
    getNetworkSlug,
    getSubdomain,
    getNetworkChangeUrl,
    handleNetworkSlug,
    appNetworkConfig,
  };
}
