import { ref, onMounted } from 'vue';
import {
  isConnected,
  getAddress,
  setAllowed,
  WatchWalletChanges,
} from '@stellar/freighter-api';

export function useAccount() {
  const address = ref<string | undefined>(undefined);
  const loading = ref(true);

  onMounted(async () => {
    if (address.value !== undefined) return;

    try {
      const user = await getAddress();
      if (user) address.value = user.address;
      const Watcher = new WatchWalletChanges(3000);
      Watcher.watch(watcherResults => {
        address.value = watcherResults.address;
      });
    } finally {
      loading.value = false;
    }
  });

  return {
    address: address,
    loading,
  };
}
