import { Config } from '../types';
import keys from './keys';
import contracts from './contracts';
import pools from './pools';
import tokenlists from './tokenlists';
import tokens from './tokens';
import rateProviders from './rateProviders';

const config: Config = {
  key: '100',
  chainId: 100,
  //   layerZeroChainId: 110,
  supportsVeBalSync: true,
  chainName: 'Solana',
  name: 'Solana',
  shortName: 'Solana',
  monorepoName: 'solana',
  slug: 'solana',
  network: 'solana',
  trustWalletNetwork: 'solana',
  unknown: false,
  visibleInUI: true,
  testNetwork: false,
  rpc: `https://forno.celo.org`,
  ws: `wss://forno.celo.org/ws`,
  publicRpc: 'https://forno.celo.org',
  explorer: 'https://stellar.expert',
  explorerName: 'Stellar',
  subgraph:
    'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-arbitrum-v2',
  balancerApi: 'https://api.balancer.fi',
  poolsUrlV2: '',
  subgraphs: {
    main: [
      'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-arbitrum-v2',
    ],
    aave: '',
    gauge:
      'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-gauges-arbitrum',
    blocks:
      'https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-one-blocks',
  },
  bridgeUrl: 'https://bridge.orbitchain.io/celo/celo',
  supportsEIP1559: true,
  supportsElementPools: false,
  blockTime: 5,
  nativeAsset: {
    name: 'Stellar',
    address: '0x0000000000000000000000000000000000000000',
    symbol: 'XLM',
    decimals: 18,
    deeplinkId: 'stellar',
    logoURI: 'tokens/solana.svg',
    minTransactionBuffer: '0.1',
  },
  vaultToken: {
    name: 'Vault',
    address: '0x0000000000000000000000000000000000000000',
    managerAddress: '0x0000000000000000000000000000000000000000',
    symbol: 'VAULT',
    decimals: 12,
    assetDecimals: 6,
    assetSymbol: 'USDC',
    assetAddress: '0xcebA9300f2b948710d2653dD7B07f33A8B32118C',
    logoURI: 'tokens/celo.png',
    poolId: 493,
  },
  thirdParty: {
    coingecko: {
      nativeAssetId: 'ethereum',
      platformId: 'alfajores-one',
    },
    apyVision: {
      networkName: 'alfajores',
    },
  },
  addresses: {
    ...contracts,
  },
  pools,
  tokens,
  keys,
  gauges: {
    type: 3,
    weight: 0,
  },
  tokenlists,
  rateProviders,
};

export default config;
