import { Config } from './types';

import alfajores from './alfajores';
import mainnet from './mainnet';
import polygon from './polygon';
import amoy from './amoy';
import celo from './celo';
import stellar from './stellar';
import solana from './solana';
import base from './base';
import mumbai from './mumbai';
import sepolia from './sepolia';
import baseSepolia from './base-sepolia';

// We don't import Network from sdk to avoid extra bundle size when loading app (while the SDK is not tree-shakable)
export enum Network {
  MAINNET = 1,
  BASE = 8453,
  POLYGON = 137,
  ALFAJORES = 44787,
  CELO = 42220,
  MUMBAI = 80001,
  AMOY = 80002,
  SEPOLIA = 11155111,
  BASE_SEPOLIA = 84532,
}
export enum OtherNetwork {
  STELLAR = 99,
  SOLANA = 100,
}

export type AllNetwork = Network | OtherNetwork;

const config: Record<Network, Config> = {
  [Network.MAINNET]: mainnet,
  [Network.POLYGON]: polygon,
  [Network.ALFAJORES]: alfajores,
  [Network.CELO]: celo,
  [Network.BASE]: base,
  [Network.MUMBAI]: mumbai,
  [Network.AMOY]: amoy,
  [Network.SEPOLIA]: sepolia,
  [Network.BASE_SEPOLIA]: baseSepolia,
};

export const allConfig: Record<AllNetwork, Config> = {
  ...config,
  [OtherNetwork.STELLAR]: stellar,
  [OtherNetwork.SOLANA]: solana,
};

export const prodNetworkConfigs = [celo, polygon, base, stellar, solana];
export const devNetworkConfigs = [alfajores, amoy, sepolia, baseSepolia];

export default config;
