<script setup lang="ts">
import { buildConnectorIconURL, buildNetworkIconURL } from '@/lib/utils/urls';

interface Tab {
  value: string | number;
  icon: string;
  label: string;
}

type Props = {
  tabs: Tab[];
  modelValue: string | number;
  noPad: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  noPad: false,
});

const emit = defineEmits(['selected', 'update:modelValue']);

function isActiveTab(tab: Tab): boolean {
  return props.modelValue === tab.value;
}

function onClick(tab: Tab) {
  emit('selected', tab.value);
  emit('update:modelValue', tab.value);
}

const containerClasses = computed(() => {
  return {
    'px-4': !props.noPad,
  };
});

function stateClasses(tab: Tab): Record<string, boolean> {
  return {
    'border-b-0 dark:bg-[rgba(255,255,255,0.2)] rounded-3xl border-blue-600 dark:border-blue-400 text-blue-600 dark:text-blue-400 hover:text-blue-500 dark:border-blue-500 font-semibold':
      isActiveTab(tab),
    'hover:text-purple-600 dark:hover:text-yellow-500 dark:border-gray-700 transition-colors':
      !isActiveTab(tab),
  };
}

function getIcon(wallet: string): string {
  const result = new URL(
    // https://vitejs.dev/guide/assets.html#new-url-url-import-meta-url
    // Warning: Don't extract this template into a variable or it will stop working in production builds
    `/src/assets/images/connectors/${wallet}.svg`,
    import.meta.url
  ).href;
  debugger;
  return result;
}

const lastTab = computed(() => props.tabs[props.tabs.length - 1]);
</script>

<template>
  <div>
    <div :class="['wallet-tab-container text-secondary', containerClasses]">
      <div
        v-for="(tab, i) in props.tabs"
        :key="i"
        :class="['wallet-tab font-medium', stateClasses(tab)]"
        @click="onClick(tab)"
      >
        <img :src="buildNetworkIconURL(tab.icon)" class="object-contain mr-4" />
        {{ tab.label }}
      </div>
      <!--      <div :class="['wallet-tab font-medium', stateClasses(lastTab)]">-->
      <!--        <slot></slot>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<style>
.wallet-tab {
  @apply py-2 cursor-pointer w-full flex justify-start px-5 items-center;
}

.wallet-tab:last-child {
  @apply mr-0;
}

.wallet-tab-container {
  @apply flex rounded-full dark:bg-[#1a1a1a] justify-around py-2 px-2;
}
</style>
